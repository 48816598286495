@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import "~react-semantic-toasts/styles/react-semantic-alert.css";
.lp-0 {
  padding-left: 0px;
}
.lm-0 {
  margin-left: 0px;
}
.nlm-0 {
  margin-left: 0px;
}
.rp-0 {
  padding-right: 0px;
}
.rm-0 {
  margin-right: 0px;
}
.nrm-0 {
  margin-right: 0px;
}
.tp-0 {
  padding-top: 0px;
}
.tm-0 {
  margin-top: 0px;
}
.ntm-0 {
  margin-top: 0px;
}
.bp-0 {
  padding-bottom: 0px;
}
.bm-0 {
  margin-bottom: 0px;
}
.nbm-0 {
  margin-bottom: 0px;
}
.hp-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.sp-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.hm-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.sm-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.bt-0 {
  border-top-width: 0px;
  border-top-style: solid;
}
.bb-0 {
  border-bottom-width: 0px;
  border-bottom-style: solid;
}
.br-0 {
  border-right-width: 0px;
  border-right-style: solid;
}
.bl-0 {
  border-left-width: 0px;
  border-left-style: solid;
}
.b-0 {
  border-width: 0px;
  border-style: solid;
}
.br-0 {
  border-radius: 0px;
}
.opacity-0 {
  opacity: 0;
}
.lp-5 {
  padding-left: 5px;
}
.lm-5 {
  margin-left: 5px;
}
.nlm-5 {
  margin-left: -5px;
}
.rp-5 {
  padding-right: 5px;
}
.rm-5 {
  margin-right: 5px;
}
.nrm-5 {
  margin-right: -5px;
}
.tp-5 {
  padding-top: 5px;
}
.tm-5 {
  margin-top: 5px;
}
.ntm-5 {
  margin-top: -5px;
}
.bp-5 {
  padding-bottom: 5px;
}
.bm-5 {
  margin-bottom: 5px;
}
.nbm-5 {
  margin-bottom: -5px;
}
.hp-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.sp-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.hm-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.sm-5 {
  margin-left: 5px;
  margin-right: 5px;
}
.bt-1 {
  border-top-width: 1px;
  border-top-style: solid;
}
.bb-1 {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.br-1 {
  border-right-width: 1px;
  border-right-style: solid;
}
.bl-1 {
  border-left-width: 1px;
  border-left-style: solid;
}
.b-1 {
  border-width: 1px;
  border-style: solid;
}
.br-5 {
  border-radius: 5px;
}
.opacity-5 {
  opacity: 0.05;
}
.lp-10 {
  padding-left: 10px;
}
.lm-10 {
  margin-left: 10px;
}
.nlm-10 {
  margin-left: -10px;
}
.rp-10 {
  padding-right: 10px;
}
.rm-10 {
  margin-right: 10px;
}
.nrm-10 {
  margin-right: -10px;
}
.tp-10 {
  padding-top: 10px;
}
.tm-10 {
  margin-top: 10px;
}
.ntm-10 {
  margin-top: -10px;
}
.bp-10 {
  padding-bottom: 10px;
}
.bm-10 {
  margin-bottom: 10px;
}
.nbm-10 {
  margin-bottom: -10px;
}
.hp-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.sp-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.hm-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.sm-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.bt-2 {
  border-top-width: 2px;
  border-top-style: solid;
}
.bb-2 {
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
.br-2 {
  border-right-width: 2px;
  border-right-style: solid;
}
.bl-2 {
  border-left-width: 2px;
  border-left-style: solid;
}
.b-2 {
  border-width: 2px;
  border-style: solid;
}
.br-10 {
  border-radius: 10px;
}
.opacity-10 {
  opacity: 0.1;
}
.lp-15 {
  padding-left: 15px;
}
.lm-15 {
  margin-left: 15px;
}
.nlm-15 {
  margin-left: -15px;
}
.rp-15 {
  padding-right: 15px;
}
.rm-15 {
  margin-right: 15px;
}
.nrm-15 {
  margin-right: -15px;
}
.tp-15 {
  padding-top: 15px;
}
.tm-15 {
  margin-top: 15px;
}
.ntm-15 {
  margin-top: -15px;
}
.bp-15 {
  padding-bottom: 15px;
}
.bm-15 {
  margin-bottom: 15px;
}
.nbm-15 {
  margin-bottom: -15px;
}
.hp-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.sp-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.hm-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.sm-15 {
  margin-left: 15px;
  margin-right: 15px;
}
.bt-3 {
  border-top-width: 3px;
  border-top-style: solid;
}
.bb-3 {
  border-bottom-width: 3px;
  border-bottom-style: solid;
}
.br-3 {
  border-right-width: 3px;
  border-right-style: solid;
}
.bl-3 {
  border-left-width: 3px;
  border-left-style: solid;
}
.b-3 {
  border-width: 3px;
  border-style: solid;
}
.br-15 {
  border-radius: 15px;
}
.opacity-15 {
  opacity: 0.15;
}
.lp-20 {
  padding-left: 20px;
}
.lm-20 {
  margin-left: 20px;
}
.nlm-20 {
  margin-left: -20px;
}
.rp-20 {
  padding-right: 20px;
}
.rm-20 {
  margin-right: 20px;
}
.nrm-20 {
  margin-right: -20px;
}
.tp-20 {
  padding-top: 20px;
}
.tm-20 {
  margin-top: 20px;
}
.ntm-20 {
  margin-top: -20px;
}
.bp-20 {
  padding-bottom: 20px;
}
.bm-20 {
  margin-bottom: 20px;
}
.nbm-20 {
  margin-bottom: -20px;
}
.hp-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.sp-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.hm-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.sm-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.bt-4 {
  border-top-width: 4px;
  border-top-style: solid;
}
.bb-4 {
  border-bottom-width: 4px;
  border-bottom-style: solid;
}
.br-4 {
  border-right-width: 4px;
  border-right-style: solid;
}
.bl-4 {
  border-left-width: 4px;
  border-left-style: solid;
}
.b-4 {
  border-width: 4px;
  border-style: solid;
}
.br-20 {
  border-radius: 20px;
}
.opacity-20 {
  opacity: 0.2;
}
.lp-25 {
  padding-left: 25px;
}
.lm-25 {
  margin-left: 25px;
}
.nlm-25 {
  margin-left: -25px;
}
.rp-25 {
  padding-right: 25px;
}
.rm-25 {
  margin-right: 25px;
}
.nrm-25 {
  margin-right: -25px;
}
.tp-25 {
  padding-top: 25px;
}
.tm-25 {
  margin-top: 25px;
}
.ntm-25 {
  margin-top: -25px;
}
.bp-25 {
  padding-bottom: 25px;
}
.bm-25 {
  margin-bottom: 25px;
}
.nbm-25 {
  margin-bottom: -25px;
}
.hp-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.sp-25 {
  padding-left: 25px;
  padding-right: 25px;
}
.hm-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}
.sm-25 {
  margin-left: 25px;
  margin-right: 25px;
}
.bt-5 {
  border-top-width: 5px;
  border-top-style: solid;
}
.bb-5 {
  border-bottom-width: 5px;
  border-bottom-style: solid;
}
.br-5 {
  border-right-width: 5px;
  border-right-style: solid;
}
.bl-5 {
  border-left-width: 5px;
  border-left-style: solid;
}
.b-5 {
  border-width: 5px;
  border-style: solid;
}
.br-25 {
  border-radius: 25px;
}
.opacity-25 {
  opacity: 0.25;
}
.lp-30 {
  padding-left: 30px;
}
.lm-30 {
  margin-left: 30px;
}
.nlm-30 {
  margin-left: -30px;
}
.rp-30 {
  padding-right: 30px;
}
.rm-30 {
  margin-right: 30px;
}
.nrm-30 {
  margin-right: -30px;
}
.tp-30 {
  padding-top: 30px;
}
.tm-30 {
  margin-top: 30px;
}
.ntm-30 {
  margin-top: -30px;
}
.bp-30 {
  padding-bottom: 30px;
}
.bm-30 {
  margin-bottom: 30px;
}
.nbm-30 {
  margin-bottom: -30px;
}
.hp-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.sp-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.hm-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.sm-30 {
  margin-left: 30px;
  margin-right: 30px;
}
.bt-6 {
  border-top-width: 6px;
  border-top-style: solid;
}
.bb-6 {
  border-bottom-width: 6px;
  border-bottom-style: solid;
}
.br-6 {
  border-right-width: 6px;
  border-right-style: solid;
}
.bl-6 {
  border-left-width: 6px;
  border-left-style: solid;
}
.b-6 {
  border-width: 6px;
  border-style: solid;
}
.br-30 {
  border-radius: 30px;
}
.opacity-30 {
  opacity: 0.3;
}
.lp-35 {
  padding-left: 35px;
}
.lm-35 {
  margin-left: 35px;
}
.nlm-35 {
  margin-left: -35px;
}
.rp-35 {
  padding-right: 35px;
}
.rm-35 {
  margin-right: 35px;
}
.nrm-35 {
  margin-right: -35px;
}
.tp-35 {
  padding-top: 35px;
}
.tm-35 {
  margin-top: 35px;
}
.ntm-35 {
  margin-top: -35px;
}
.bp-35 {
  padding-bottom: 35px;
}
.bm-35 {
  margin-bottom: 35px;
}
.nbm-35 {
  margin-bottom: -35px;
}
.hp-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}
.sp-35 {
  padding-left: 35px;
  padding-right: 35px;
}
.hm-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}
.sm-35 {
  margin-left: 35px;
  margin-right: 35px;
}
.bt-7 {
  border-top-width: 7px;
  border-top-style: solid;
}
.bb-7 {
  border-bottom-width: 7px;
  border-bottom-style: solid;
}
.br-7 {
  border-right-width: 7px;
  border-right-style: solid;
}
.bl-7 {
  border-left-width: 7px;
  border-left-style: solid;
}
.b-7 {
  border-width: 7px;
  border-style: solid;
}
.br-35 {
  border-radius: 35px;
}
.opacity-35 {
  opacity: 0.35;
}
.lp-40 {
  padding-left: 40px;
}
.lm-40 {
  margin-left: 40px;
}
.nlm-40 {
  margin-left: -40px;
}
.rp-40 {
  padding-right: 40px;
}
.rm-40 {
  margin-right: 40px;
}
.nrm-40 {
  margin-right: -40px;
}
.tp-40 {
  padding-top: 40px;
}
.tm-40 {
  margin-top: 40px;
}
.ntm-40 {
  margin-top: -40px;
}
.bp-40 {
  padding-bottom: 40px;
}
.bm-40 {
  margin-bottom: 40px;
}
.nbm-40 {
  margin-bottom: -40px;
}
.hp-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.sp-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.hm-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.sm-40 {
  margin-left: 40px;
  margin-right: 40px;
}
.bt-8 {
  border-top-width: 8px;
  border-top-style: solid;
}
.bb-8 {
  border-bottom-width: 8px;
  border-bottom-style: solid;
}
.br-8 {
  border-right-width: 8px;
  border-right-style: solid;
}
.bl-8 {
  border-left-width: 8px;
  border-left-style: solid;
}
.b-8 {
  border-width: 8px;
  border-style: solid;
}
.br-40 {
  border-radius: 40px;
}
.opacity-40 {
  opacity: 0.4;
}
.lp-45 {
  padding-left: 45px;
}
.lm-45 {
  margin-left: 45px;
}
.nlm-45 {
  margin-left: -45px;
}
.rp-45 {
  padding-right: 45px;
}
.rm-45 {
  margin-right: 45px;
}
.nrm-45 {
  margin-right: -45px;
}
.tp-45 {
  padding-top: 45px;
}
.tm-45 {
  margin-top: 45px;
}
.ntm-45 {
  margin-top: -45px;
}
.bp-45 {
  padding-bottom: 45px;
}
.bm-45 {
  margin-bottom: 45px;
}
.nbm-45 {
  margin-bottom: -45px;
}
.hp-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}
.sp-45 {
  padding-left: 45px;
  padding-right: 45px;
}
.hm-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}
.sm-45 {
  margin-left: 45px;
  margin-right: 45px;
}
.bt-9 {
  border-top-width: 9px;
  border-top-style: solid;
}
.bb-9 {
  border-bottom-width: 9px;
  border-bottom-style: solid;
}
.br-9 {
  border-right-width: 9px;
  border-right-style: solid;
}
.bl-9 {
  border-left-width: 9px;
  border-left-style: solid;
}
.b-9 {
  border-width: 9px;
  border-style: solid;
}
.br-45 {
  border-radius: 45px;
}
.opacity-45 {
  opacity: 0.45;
}
.lp-50 {
  padding-left: 50px;
}
.lm-50 {
  margin-left: 50px;
}
.nlm-50 {
  margin-left: -50px;
}
.rp-50 {
  padding-right: 50px;
}
.rm-50 {
  margin-right: 50px;
}
.nrm-50 {
  margin-right: -50px;
}
.tp-50 {
  padding-top: 50px;
}
.tm-50 {
  margin-top: 50px;
}
.ntm-50 {
  margin-top: -50px;
}
.bp-50 {
  padding-bottom: 50px;
}
.bm-50 {
  margin-bottom: 50px;
}
.nbm-50 {
  margin-bottom: -50px;
}
.hp-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.sp-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.hm-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.sm-50 {
  margin-left: 50px;
  margin-right: 50px;
}
.bt-10 {
  border-top-width: 10px;
  border-top-style: solid;
}
.bb-10 {
  border-bottom-width: 10px;
  border-bottom-style: solid;
}
.br-10 {
  border-right-width: 10px;
  border-right-style: solid;
}
.bl-10 {
  border-left-width: 10px;
  border-left-style: solid;
}
.b-10 {
  border-width: 10px;
  border-style: solid;
}
.br-50 {
  border-radius: 50px;
}
.opacity-50 {
  opacity: 0.5;
}
.text-blue {
  color: #002F4E;
}
.bg-blue {
  background-color: #002F4E;
}
.blc-blue {
  border-left-color: #002F4E;
}
.brc-blue {
  border-right-color: #002F4E;
}
.btc-blue {
  border-top-color: #002F4E;
}
.bbc-blue {
  border-bottom-color: #002F4E;
}
.text-green {
  color: #339933;
}
.bg-green {
  background-color: #339933;
}
.blc-green {
  border-left-color: #339933;
}
.brc-green {
  border-right-color: #339933;
}
.btc-green {
  border-top-color: #339933;
}
.bbc-green {
  border-bottom-color: #339933;
}
.text-orange {
  color: #F2711C;
}
.bg-orange {
  background-color: #F2711C;
}
.blc-orange {
  border-left-color: #F2711C;
}
.brc-orange {
  border-right-color: #F2711C;
}
.btc-orange {
  border-top-color: #F2711C;
}
.bbc-orange {
  border-bottom-color: #F2711C;
}
.text-pink {
  color: #E03997;
}
.bg-pink {
  background-color: #E03997;
}
.blc-pink {
  border-left-color: #E03997;
}
.brc-pink {
  border-right-color: #E03997;
}
.btc-pink {
  border-top-color: #E03997;
}
.bbc-pink {
  border-bottom-color: #E03997;
}
.text-purple {
  color: #A333C8;
}
.bg-purple {
  background-color: #A333C8;
}
.blc-purple {
  border-left-color: #A333C8;
}
.brc-purple {
  border-right-color: #A333C8;
}
.btc-purple {
  border-top-color: #A333C8;
}
.bbc-purple {
  border-bottom-color: #A333C8;
}
.text-violet {
  color: #5F7FBD;
}
.bg-violet {
  background-color: #5F7FBD;
}
.blc-violet {
  border-left-color: #5F7FBD;
}
.brc-violet {
  border-right-color: #5F7FBD;
}
.btc-violet {
  border-top-color: #5F7FBD;
}
.bbc-violet {
  border-bottom-color: #5F7FBD;
}
.text-red {
  color: #DB2828;
}
.bg-red {
  background-color: #DB2828;
}
.blc-red {
  border-left-color: #DB2828;
}
.brc-red {
  border-right-color: #DB2828;
}
.btc-red {
  border-top-color: #DB2828;
}
.bbc-red {
  border-bottom-color: #DB2828;
}
.text-teal {
  color: #4270D3;
}
.bg-teal {
  background-color: #4270D3;
}
.blc-teal {
  border-left-color: #4270D3;
}
.brc-teal {
  border-right-color: #4270D3;
}
.btc-teal {
  border-top-color: #4270D3;
}
.bbc-teal {
  border-bottom-color: #4270D3;
}
.text-yellow {
  color: #FBBD08;
}
.bg-yellow {
  background-color: #FBBD08;
}
.blc-yellow {
  border-left-color: #FBBD08;
}
.brc-yellow {
  border-right-color: #FBBD08;
}
.btc-yellow {
  border-top-color: #FBBD08;
}
.bbc-yellow {
  border-bottom-color: #FBBD08;
}
.text-black {
  color: #1B1C1D;
}
.bg-black {
  background-color: #1B1C1D;
}
.blc-black {
  border-left-color: #1B1C1D;
}
.brc-black {
  border-right-color: #1B1C1D;
}
.btc-black {
  border-top-color: #1B1C1D;
}
.bbc-black {
  border-bottom-color: #1B1C1D;
}
.text-grey {
  color: #767676;
}
.bg-grey {
  background-color: #767676;
}
.blc-grey {
  border-left-color: #767676;
}
.brc-grey {
  border-right-color: #767676;
}
.btc-grey {
  border-top-color: #767676;
}
.bbc-grey {
  border-bottom-color: #767676;
}
.text-white {
  color: #FFFFFF;
}
.bg-white {
  background-color: #FFFFFF;
}
.blc-white {
  border-left-color: #FFFFFF;
}
.brc-white {
  border-right-color: #FFFFFF;
}
.btc-white {
  border-top-color: #FFFFFF;
}
.bbc-white {
  border-bottom-color: #FFFFFF;
}
.text-brown {
  color: #A5673F;
}
.bg-brown {
  background-color: #A5673F;
}
.blc-brown {
  border-left-color: #A5673F;
}
.brc-brown {
  border-right-color: #A5673F;
}
.btc-brown {
  border-top-color: #A5673F;
}
.bbc-brown {
  border-bottom-color: #A5673F;
}
.border-grey {
  border-color: rgba(34, 36, 38, 0.15);
}
.fr {
  float: right;
}
.fl {
  float: left;
}
.clickable {
  cursor: pointer;
}
.di {
  display: inline;
}
.db {
  display: block;
}
.dib {
  display: inline-block;
}
.clear:after {
  content: "";
  clear: both;
  display: table;
}
.text-bold {
  font-weight: bold;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-capitalize {
  text-transform: capitalize;
}
.vc {
  display: flex;
  align-items: center;
}
.straight-border {
  border-radius: 0 !important;
}
.ellipsis {
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-col {
  flex-direction: column;
}
.price-zero-item {
  background: #e79191;
  border: none;
  box-shadow: none;
}
#order-menu {
  background: transparent;
  border: none;
  box-shadow: none;
}
#order-menu .item {
  padding: 0;
}
#order-menu .order-icon {
  color: white;
}
.line-through {
  text-decoration: line-through;
}
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/* This is the single variable that controls them all */
/* The size of page text  */
/*-------------------
    Border Radius
--------------------*/
/* See Power-user section below
   for explanation of @px variables
*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/* Line Height Default For Inputs in Browser (Descenders are 17px at 14px base em) */
/*-------------------
    Focused Input
--------------------*/
/* Used on inputs, textarea etc */
/* Used on dropdowns, other larger blocks */
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/* Inverted */
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/* Differentiating Neutrals */
/* Differentiating Layers */
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/* For source only. Modified in gulp for dist */
/*-------------------
       Em Sizes
--------------------*/
/*
  This rounds @size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/* Maximum Glyph Width of Icon */
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*******************************
     User Global Variables
*******************************/
.page-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  min-height: 100vh;
}
.page-sidenav {
  background-color: #2d862d;
  justify-content: space-between;
  transition: width 500ms;
  flex-direction: column;
  flex-shrink: 0;
  display: flex;
  width: 80px;
}
.page-sidenav .sidenav-item {
  color: #FFFFFF;
  border-right: 4px solid #133913;
}
.page-sidenav .sidenav-item.active {
  border-right: 4px solid #79d279;
  background-color: rgba(51, 153, 51, 0.6);
}
@media only screen and (min-width: 992px) {
  .page-sidenav .sidenav-item {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .page-sidenav .sidenav-item > span {
    font-size: 1.2rem;
    margin-left: 0.7rem;
    animation: fadein 2s;
  }
}
.mini .page-sidenav .sidenav-item {
  padding-left: 1rem;
  padding-right: 1rem;
}
.mini .page-sidenav .sidenav-item > span {
  font-size: 1.2rem;
  margin-left: 0.7rem;
  animation: fadein 2s;
}
@keyframes fadein {
  from {
    opacity: 0;
    display: none;
  }
  to {
    opacity: 1;
    display: inherit;
  }
}
@media only screen and (max-width: 991px) {
  .page-sidenav {
    text-align: center;
  }
  .page-sidenav i.icon {
    font-size: 1.5rem;
  }
  .page-sidenav i.icon {
    font-size: 1.5rem;
  }
  .page-sidenav .hide-on-mini {
    display: none !important;
  }
}
.page-sidenav.mini {
  text-align: center;
}
.page-sidenav.mini i.icon {
  font-size: 1.5rem;
}
.page-sidenav.mini .hide-on-mini {
  display: none !important;
}
@media only screen and (min-width: 992px) {
  .page-sidenav:not(.mini) {
    width: 180px;
    text-align: left;
  }
}
@media only screen and (min-width: 1200px) {
  .page-sidenav:not(.mini) {
    width: 220px;
  }
}
.page-sidenav:not(.mini) .sidenav-name-initials {
  display: none;
}
.page-container {
  background-color: #f6f6f6;
  flex-grow: 1;
  min-width: 0;
}
.page-container > .ui.grid {
  margin-left: 0;
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  .page-container > .ui.grid {
    margin-top: 0;
  }
}
@media print {
  .page-container {
    background-color: #FFFFFF;
  }
}
.page-topbar {
  background-color: #FFFFFF;
}
@media print {
  .invoice-view-page .summary-area .ui.segment,
  .invoice-view-page .summary-area .ui.message {
    border: 0 none !important;
    padding-left: 0 !important;
    box-shadow: none !important;
    padding-bottom: 0.5em !important;
    background: transparent !important;
  }
  .invoice-view-page .summary-area .ui.segment .lp-5,
  .invoice-view-page .summary-area .ui.message .lp-5 {
    padding-left: 0 !important;
  }
}
@media print {
  .no-print {
    display: none !important;
  }
}
.only-print {
  display: none !important;
}
@media print {
  .only-print {
    display: block !important;
  }
}
@media print {
  body {
    zoom: 0.65;
  }
  .page-topbar {
    padding-bottom: 0 !important;
  }
  .invoice-view-dimmer {
    padding-top: 0 !important;
  }
}
.signature-area {
  width: 280%;
  margin-left: -180%;
}
.signed-by {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.product-search-container .ui.dropdown .menu .item.addition {
  background-color: #FFE21F;
  color: #545454;
}
.hide-number-arrows input {
  -moz-appearance: textfield;
}
.hide-number-arrows input::-webkit-outer-spin-button,
.hide-number-arrows input::-webkit-inner-spin-button {
  margin: 0;
  display: none;
  -webkit-appearance: none;
}
.product-list-column-type,
.product-list-column-actions {
  width: 50px;
}
.product-list-column-price {
  width: 180px;
}
.item-log-note-column {
  width: 180px;
  padding: 10px;
}
.product-list-column-total {
  width: 10px;
}
.ag-grid-editor-segment {
  width: 300px;
  padding: 15px;
}
.ui.button.profile-color {
  color: #FFFFFF;
  padding-left: 14px;
  padding-right: 14px;
}
.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled,
.ui.form .field.disabled > label,
.ui.form .fields.disabled > label,
.ui.disabled.dropdown,
.ui.dropdown .menu > .disabled.item {
  opacity: 0.8;
}
.fields.grouped .ui.input input {
  width: 100px;
}
.fields.grouped .ui.label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fields.grouped .ui.input:first-child input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fields.grouped .ui.input:nth-child(2) input {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
@media screen and (max-width: 768px) {
  .page-container .ag-theme-balham .ag-cell {
    padding-left: 4px;
    padding-right: 4px;
  }
  .page-container .ag-theme-balham .ag-header-cell,
  .page-container .ag-theme-balham .ag-header-group-cell {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.page-container .ag-theme-balham .ag-root {
  border-color: #e9e9e9;
}
.page-container .ag-theme-balham.inventory-grid .ag-header-cell-label .ag-header-cell-text {
  display: inline-block;
  text-overflow: unset;
  white-space: pre-line;
}
.ui.segment.customer-grid {
  padding-top: 0;
  padding-bottom: 0;
}
.camera-container {
  position: relative;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.camera-container-hide {
  display: none;
}
#interactive.viewport {
  width: 100%;
  height: 100%;
}
#interactive.viewport canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#interactive.viewport canvas.drawingBuffer {
  width: 100%;
  height: 100%;
}
#interactive video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
video.drawingBuffer {
  width: 100%;
  height: 100%;
}
.photo-button {
  width: 100px;
  height: 100px;
  top: 78vh;
  left: 50%;
  margin-left: -50px;
  position: absolute;
}
#circle {
  position: absolute;
  top: 12%;
  left: 12%;
  bottom: 12%;
  right: 12%;
  border-radius: 100%;
  background-color: #2d862d;
  opacity: 0;
}
#ring {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  border: 0.5em solid #2d862d;
  opacity: 0.8;
}
.photo-button #circle,
.photo-button #ring {
  transition: all 0.25s;
}
.photo-button:hover #circle {
  opacity: 1;
}
.photo-button:active #ring {
  opacity: 1;
}
.photo-button:active #circle {
  opacity: 0.5;
}
.webcam {
  height: 80vh;
  width: 100%;
}
