@import "~semantic-ui-less/themes/default/globals/site.variables";
@import "../semantic-ui/site/globals/site.variables";

.page-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    min-height: 100vh;
}

.page-sidenav {
    background-color: darken(@green, 5%);
    justify-content: space-between;
    transition: width 500ms;
    flex-direction: column;
    flex-shrink: 0;
    display: flex;
    width: 80px;

    .sidenav-item {
        color: @white;
        border-right: 4px solid darken(@green, 25%);

        .mini () {
            padding-left: 1rem;
            padding-right: 1rem;

            & > span {
                font-size: 1.2rem;
                margin-left: 0.7rem;
                animation: fadein 2s;
            }
        }

        &.active {
            border-right: 4px solid lighten(@green, 25%);
            background-color: fadeout(@green, 40%);
        }

        @media only screen and (min-width: @computerBreakpoint) {
            .mini();
        }

        .mini & {
            .mini();
        }
    }

    @keyframes fadein {
        from {
            opacity: 0;
            display: none;
        }
        to {
            opacity: 1;
            display: inherit;
        }
    }

    .mini () {
        text-align: center;

        i.icon {
            font-size: 1.5rem;
        }
    }

    @media only screen and (max-width: @largestTabletScreen) {
        .mini();
    }

    &.mini {
        .mini();

        .hide-on-mini {
            display: none !important;
        }
    }

    &:not(.mini) {
        @media only screen and (min-width: @computerBreakpoint) {
            width: 180px;
            text-align: left;
        }

        @media only screen and (min-width: @largeMonitorBreakpoint) {
            width: 220px;
        }

        .sidenav-name-initials {
            display: none;
        }
    }
}

.page-container {
    background-color: lighten(@grey, 50%);
    flex-grow: 1;
    min-width: 0;

    & > .ui.grid {
        margin-left: 0;
        margin-right: 0;

        @media screen and (max-width: @tabletBreakpoint) {
            margin-top: 0;
        }
    }

    @media print {
        background-color: @white;
    }
}

.page-topbar {
    background-color: @white;
}

@media print {
    .invoice-view-page .summary-area {
        .ui.segment,
        .ui.message {
            border: 0 none !important;
            padding-left: 0 !important;
            box-shadow: none !important;
            padding-bottom: 0.5em !important;
            background: transparent !important;

            .lp-5 {
                padding-left: 0 !important;
            }
        }
    }
}

.no-print {
    @media print {
        display: none !important;
    }
}

.only-print {
    display: none !important;
    @media print {
        display: block !important;
    }
}

@media print {
    body {
        zoom: 0.65;
    }
    .page-topbar {
        padding-bottom: 0 !important;
    }
    .invoice-view-dimmer {
        padding-top: 0 !important;
    }
}

.signature-area {
    width: 280%;
    margin-left: -180%;
}

.signed-by{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.product-search-container {
    .ui.dropdown .menu .item.addition {
        background-color: @lightYellow;
        color: @lightBlack;
    }
}

// hack to hide number arrows in some of the input fields where it's useless
.hide-number-arrows input {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        margin: 0;
        display: none;
        -webkit-appearance: none;
    }
}

.product-list-column-type,
.product-list-column-actions {
    width: 50px;
}

.product-list-column-price {
    width: 180px;
}
.item-log-note-column {
    width: 180px;
    padding: 10px;
}
.product-list-column-total {
    width: 10px;
}

// ag grid overwrites
.ag-grid-editor-segment {
    width: 300px;
    padding: 15px;
}

// overlay
.ui.button.profile-color {
    color: @white;
    padding-left: 14px;
    padding-right: 14px;
}

// overwrites semantic ui
.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled,
.ui.form .field.disabled > label,
.ui.form .fields.disabled > label,
.ui.disabled.dropdown,
.ui.dropdown .menu > .disabled.item {
    opacity: 0.8;
}
.fields.grouped {
    .ui.input input {
        width: 100px;
    }

    .ui.label {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .ui.input:first-child input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .ui.input:nth-child(2) input {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

// overwrites ag grid
.page-container {
    .ag-theme-balham {
        @media screen and (max-width: @tabletBreakpoint) {
            .ag-cell {
                padding-left: 4px;
                padding-right: 4px;
            }

            .ag-header-cell,
            .ag-header-group-cell {
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        .ag-root {
            border-color: lighten(@grey, 45%);
        }

        &.inventory-grid {
            .ag-header-cell-label .ag-header-cell-text{
                display: inline-block;
                text-overflow: unset;
                white-space: pre-line;
            }
        }
    }
}

.ui.segment.customer-grid {
    padding-top: 0;
    padding-bottom: 0;
}

// Camera container for scanner
.camera-container {
    position: relative;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.camera-container-hide {
    display: none;
}

#interactive.viewport {
    width: 100%;
    height: 100%;
    canvas {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    canvas.drawingBuffer {
        width: 100%;
        height: 100%;
    }
}
#interactive video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
video.drawingBuffer {
    width: 100%;
    height: 100%;
}


.photo-button {
    width: 100px;
    height: 100px;
    top: 78vh;
    left: 50%;
    margin-left: -50px;
    position: absolute;
}
#circle {
    position: absolute;
    top: 12%;
    left: 12%;
    bottom: 12%;
    right: 12%;
    border-radius: 100%;
    background-color: #2d862d;
    opacity: 0;
}
#ring {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    border-radius: 100%;
    border: 0.5em solid #2d862d;
    opacity: 0.8;
}
.photo-button #circle, .photo-button #ring {
    transition: all 0.25s;
}
.photo-button:hover #circle {
    opacity: 1;
}
.photo-button:active #ring {
    opacity: 1;
}
.photo-button:active #circle {
    opacity: 0.5;
}


.webcam{
    height: 80vh;
    width: 100%;
}

//@media (max-width:600px) {
//    .webcam{
//        height: 100vh;
//        width: 100%;
//    }
//}
