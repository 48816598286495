@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import "~react-semantic-toasts/styles/react-semantic-alert.css";

@paddings: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50;
each(@paddings, {
	.lp-@{value} {
		padding-left: @value * 1px;
	}
	.lm-@{value} {
		margin-left: @value * 1px;
	}
	.nlm-@{value} {
		margin-left: @value * -1px;
	}

	.rp-@{value} {
		padding-right: @value * 1px;
	}
	.rm-@{value} {
		margin-right: @value * 1px;
	}
	.nrm-@{value} {
		margin-right: @value * -1px;
	}

	.tp-@{value} {
		padding-top: @value * 1px;
	}
	.tm-@{value} {
		margin-top: @value * 1px;
	}
	.ntm-@{value} {
		margin-top: @value * -1px;
	}

	.bp-@{value} {
		padding-bottom: @value * 1px;
	}
	.bm-@{value} {
		margin-bottom: @value * 1px;
	}
	.nbm-@{value} {
		margin-bottom: @value * -1px;
	}

	.hp-@{value} {
		padding-top: @value * 1px;
		padding-bottom: @value * 1px;
	}

	.sp-@{value} {
		padding-left: @value * 1px;
		padding-right: @value * 1px;
	}

	.hm-@{value} {
		margin-top: @value * 1px;
		margin-bottom: @value * 1px;
	}

	.sm-@{value} {
		margin-left: @value * 1px;
		margin-right: @value * 1px;
	}

    @onefifth: @value/5;
	.bt-@{onefifth} {
		border-top-width: @onefifth * 1px;
		border-top-style: solid;
	}

	.bb-@{onefifth} {
		border-bottom-width: @onefifth * 1px;
		border-bottom-style: solid;
	}

	.br-@{onefifth} {
		border-right-width: @onefifth * 1px;
		border-right-style: solid;
	}

	.bl-@{onefifth} {
		border-left-width: @onefifth * 1px;
		border-left-style: solid;
	}

	.b-@{onefifth} {
		border-width: @onefifth * 1px;
		border-style: solid;
	}

	.br-@{value} {
		border-radius: @value * 1px;
	}

	.opacity-@{value} {
		opacity: @value/100;
	}
});

@textColors: {
    blue: @blue;
    green: @green;
    orange: @orange;
    pink: @pink;
    purple: @purple;
    violet: @violet;
    red: @red;
    teal: @teal;
    yellow: @yellow;
    black: @black;
    grey: @grey;
    white: @white;
    brown: @brown;
};

each(@textColors, {
	.text-@{key}{
		color: @value;
	}

	.bg-@{key} {
		background-color: @value;
	}

	.blc-@{key} {
		border-left-color: @value;
	}

	.brc-@{key} {
		border-right-color: @value;
	}

	.btc-@{key} {
		border-top-color: @value;
	}

	.bbc-@{key} {
		border-bottom-color: @value;
	}
});

.border-grey {
    border-color: rgba(34, 36, 38, 0.15);
}

.fr {
    float: right;
}

.fl {
    float: left;
}

.clickable {
    cursor: pointer;
}

.di {
    display: inline;
}

.db {
    display: block;
}

.dib {
    display: inline-block;
}

.clear:after {
    content: "";
    clear: both;
    display: table;
}

.text-bold {
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-capitalize {
    text-transform: capitalize;
}

.vc {
    display: flex;
    align-items: center;
}

.straight-border {
    border-radius: 0 !important;
}

.ellipsis {
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.flex {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}
.flex-col {
    flex-direction: column;
}

.price-zero-item{
	background: #e79191;
	border: none;
	box-shadow: none;
}

#order-menu {
    background: transparent;
    border: none;
    box-shadow: none;
}
#order-menu .item {
    padding: 0;
}
#order-menu .order-icon {
    color: white;
}
.line-through{
	text-decoration: line-through;
}

@import "./common.less";
